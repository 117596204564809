<template>
  <div style="width: 66vw; margin: 30px 0 0 17vw">
    <h3 align="center" style="color: #17a2b8">{{ title }}</h3>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <p style="font-size: 20px; color: #17a2b8">电影信息</p>
      <el-form-item label="影片原名" style="width: 70%; display: inline-block">
        <el-input v-model="form.film_name"></el-input>
      </el-form-item>
      <el-form-item label="出品国家" style="width: 30%; display: inline-block">
        <el-input v-model="form.film_country"></el-input>
      </el-form-item>
      <el-form-item label="出品公司" style="width: 60%; display: inline-block">
        <el-input v-model="form.film_producer_company"></el-input>
      </el-form-item>
      <el-form-item label="影片导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.film_director"></el-input>
      </el-form-item>
      <el-form-item label="影片编剧" style="width: 50%; display: inline-block">
        <el-input v-model="form.film_adaptor"></el-input>
      </el-form-item>
      <el-form-item label="主要演员">
        <el-input v-model="form.film_actors"></el-input>
      </el-form-item>
      <el-form-item label="上映日期（出品国）" style="width: 40%; display: inline-block">
        <el-input v-model="form.film_release_time"></el-input>
      </el-form-item>
    </el-form>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <p style="font-size: 20px; color: #17a2b8">电影小说信息</p>
      <el-form-item label="电影小说" style="width: 70%; display: inline-block">
        <el-input v-model="form.novel_title"></el-input>
      </el-form-item>
      <el-form-item label="译者/作者/改编者" style="width: 50%; display: inline-block">
        <el-input v-model="form.novel_author"></el-input>
      </el-form-item>
      <el-form-item label="文体" style="width: 50%; display: inline-block">
        <el-input v-model="form.novel_type"></el-input>
      </el-form-item>
      <el-form-item label="发表报刊/出版单位" style="width: 70%; display: inline-block">
        <el-input v-model="form.novel_publish_company"></el-input>
      </el-form-item>
      <el-form-item label="发表/出版时间" style="width: 50%; display: inline-block">
        <el-input v-model="form.novel_release_time"></el-input>
      </el-form-item>
      <el-form-item label="字数（字）" style="width: 50%; display: inline-block">
        <el-input v-model="form.novel_word_count"></el-input>
      </el-form-item>
      <el-form-item label="海报">
        <div style="display: inline-block; vertical-align: top">
          <el-upload action="#" list-type="picture-card" :auto-upload="false" :limit="1"
            :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-change="handleChange">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
          <i class="el-icon-delete" margin-left="60%" @click="deleteImage(form.idfilms)"></i>
        </div>
        <el-image v-if="title == '修改电影小说信息' && form.novel_image" style=" border-radius: 2%; 
          display: inline-block; vertical-align: top; height: 150px; margin-left: 100px;" 
          :preview-src-list="[form.novel_image]" class="update_image" :alt="1" :src="form.novel_image">
        </el-image>
      </el-form-item>
      <el-form-item label="上传PDF">
        <div style="display: inline-block; vertical-align: top">
          <el-upload action="#" :auto-upload="false" :limit="1" 
            :on-change="handleFileChange" :on-remove="handleFileRemove" :before-upload="beforeUpload">
            <el-button type="primary">点击上传</el-button>
          </el-upload>
          <div v-if="this.pdf_file">
            <a :href="pdf_file.url" target="_blank" style="cursor: pointer;" @click="handleNewFilePreview">{{ pdf_file.name }}</a>
          </div>
        </div>
        <div v-if="title == '修改电影小说信息' && form.novel_file">
          <a :href="form.novel_file" target="_blank" style="cursor: pointer;" @click="handleFilePreview">{{ getFileName(form.novel_file) }}</a>
        </div>
      </el-form-item>
      <p style="font-size: 20px; color: #17a2b8">其他信息</p>
      <el-form-item label="备注">
        <el-input v-model="form.novel_note"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="onSubmit('form')">{{ submit }}</el-button>
        <el-button @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      rules: { },
      title: "",
      path: "",
      poster: null,
      image_path: "",
      pdf_file: null,
      pdf_path: "",
      form: {
        film_name: "",
        film_director: "",
        film_adaptor: "",
        film_actors: "",
        film_country: "",
        film_producer_company: "",
        film_release_time: "",
        novel_title: "",
        novel_author: "",
        novel_type: "",
        novel_publish_company: "",
        novel_release_time: "",
        novel_image: "",
        novel_file: "",
        novel_word_count: 0,
        novel_note: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      loading: false,
      submit: "",
      add_update_type: 0,
    };
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.add_update_film(0);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    add_update_film(ifdelete) {
      this.loading = true;
      let shouldGoBack = false; // 标志位
      let uploadPromises = []; // 存储上传的Promise
      
      axios.post(this.path, this.form)
        .then((res) => {
          this.$notify({
            title: "提示",
            message: "信息添加成功！",
            type: "success",
          });

          // 上传图片
          if (this.poster == null && ifdelete != 1) {
            shouldGoBack = true; // 设置标志位为true
          } else {
            let file = this.poster;
            let poster_param = new FormData();
            let config = { headers: { "Content-Type": "application/pdf" } };
            poster_param.append("file", file);
            if (ifdelete == 1) {
              poster_param.append("msg", "delete");
            } else {
              poster_param.append("msg", "nodelete");
            }
            if (this.add_update_type) {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                this.$route.query.filmID;  // 修改
            } else {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                res.data.id;  // 新增
            }

            uploadPromises.push(
              axios.post(this.image_path, poster_param, config)
                .then((response) => {
                  if (response.data.status == 1) {
                    this.$notify({
                      title: "提示",
                      message: "照片修改成功！",
                      type: "success",
                    });
                  } else {
                    this.$notify({
                      title: "提示",
                      message: "照片上传失败！",
                      type: "warning",
                    });
                  }
                  shouldGoBack = true; // 上传成功时设置标志位
                })
            );
          }

          // 上传PDF
          if (this.pdf_file == null) {
            shouldGoBack = true; // 设置标志位为true
          } else {
            let file = this.pdf_file;
            let pdf_param = new FormData();
            let config = { headers: { "Content-Type": "application/pdf" } };
            pdf_param.append("file", file);
            if (this.add_update_type) {
              this.pdf_path =
                "https://api.film.yingshinet.com/upload_pdf/film" +
                this.$store.state.current_film_page +
                "/" +
                this.$route.query.filmID;  // 修改
            } else {
              this.pdf_path =
                "https://api.film.yingshinet.com/upload_pdf/film" +
                this.$store.state.current_film_page +
                "/" +
                res.data.id;  // 新增
            }

            uploadPromises.push(
              axios.post(this.pdf_path, pdf_param, config)
                .then((response) => {
                  if (response.data.status == 1) {
                    this.$notify({
                      title: "提示",
                      message: "PDF文件修改成功！",
                      type: "success",
                    });
                  } else {
                    this.$notify({
                      title: "提示",
                      message: "PDF文件上传失败！",
                      type: "warning",
                    });
                  }
                  shouldGoBack = true; // 上传成功时设置标志位
                })
                .catch((error) => {
                  console.error(error);
                  this.$notify({
                    title: "提示",
                    message: "PDF上传失败（数据格式可能有问题）！",
                    type: "warning",
                  });
                })
            );
          }

          // 等待所有上传操作完成
          Promise.all(uploadPromises)
            .then(() => {
              // 判断是否需要跳转
              if (shouldGoBack) {
                this.$router.go(-1);
              }
            })
            .finally(() => {
              this.loading = false; // 结束加载状态
            });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "提示",
            message: "信息上传失败（数据格式可能有问题）！",
            type: "warning",
          });
          this.loading = false;
        });
    },
    handleChange(file, fileList) {
      this.poster = file.raw;
    },
    handleRemove(file, fileList) {
      this.poster = null;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    deleteImage(id) {
      this.$confirm("确认要删除当前图片吗？", "提示", { type: "warning" })
          .then(() => {
            this.add_update_film(1);
          })
          .catch(() => {});
    },

    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        this.pdf_file = fileList[0].raw;
      } else {
        this.pdf_file = null;
      }
    },
    handleFileRemove(file, fileList) {
      this.pdf_file = null;
    },
    handleNewFilePreview(event){
      event.preventDefault();
      const newWindow = window.open();
      newWindow.document.write(`<iframe src="${URL.createObjectURL(this.pdf_file)}" width="100%" height="100%"></iframe>`);
    },
    handleFilePreview(event) {
      event.preventDefault();
      const newWindow = window.open();
      newWindow.document.write(`<iframe src="${this.form.novel_file}" width="100%" height="100%"></iframe>`);
    },
    getFileName(filePath) {
      return filePath.split('/').pop(); // 从路径中提取文件名
    },
    beforeUpload(file) {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        this.$notify({
          title: '错误',
          message: '上传文件必须是PDF格式',
          type: 'error'
        });
      }
      return isPDF;
    },
  },

  created() {
    if (this.$route.query.filmID != undefined) {
      axios
        .post(
          "https://api.film.yingshinet.com/filmsquery" +
          this.$store.state.current_film_page,
          { idfilms: this.$route.query.filmID }
        )
        .then((response) => {
          this.form = response.data.books[0];
          this.form.user_name = this.$store.state.current_user;
        });
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page +
        "/" +
        this.$route.query.filmID;
      this.title = "修改电影小说信息";
      this.add_update_type = 1;
      this.submit = "修改";
    } else {
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page;
      this.form.user_name = this.$store.state.current_user;
      this.title = "新增电影小说信息";
      this.add_update_type = 0;
      this.submit = "新增";
    }
  },
};
</script>
<style>
.update_image:hover {
  cursor: pointer;
  transform: scale(1.2, 1.2);
  transition: 0.5s transform;
}

.el-form-item__label {
  font-size: 14px !important;
}

.el-button--primary {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner,
.el-radio__input.is-checked .el-radio__inner {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label,
.el-radio__input.is-checked+.el-radio__label {
  color: #17a2b8 !important;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #17a2b8 !important;
}

.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #17a2b8 !important;
}

.el-month-table,
.el-year-table {
  font-size: 1vw;
}

.el-radio-button__inner,
.el-radio-group {
  vertical-align: baseline;
}
</style>
