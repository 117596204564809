<template>
  <div align="center">
    <!-- 导航栏 -->
    <div>
      <el-menu :default-active="activeIndex" class="el-menu-demo menu" mode="horizontal" background-color="#17A2B8"
        text-color="#eee" active-text-color="#fff">
        <el-menu-item style="font-size:20px; padding: 0 !important; color:#fff !important"
          v-if="$store.state.login_state">【管理员系统】</el-menu-item>
        <el-menu-item index="1" @click="film_foreign_click()">外国电影汉译库</el-menu-item>
        <el-menu-item index="2" @click="film_nation_click()">民族语译制库</el-menu-item>
        <el-menu-item index="3" @click="film_china_click()">中国电影外译库</el-menu-item>
        <el-menu-item index="4" @click="film_help_click()">无障碍翻译库</el-menu-item>
        <el-menu-item index="6" @click="film_novel_click()">电影小说库</el-menu-item>
        <el-menu-item index="5" @click="film_translator_click()">译制工作者库</el-menu-item>       
        <b-button
          style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0);height:40px;font-size:15px;margin:10px 25px 0 0; float:right"
          @click="go_login()" v-if="!$store.state.login_state">登录</b-button>
        <b-button
          style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0);height:40px;font-size:15px;margin:10px 25px 0 0; float:right"
          @click="logout()" v-else>登出</b-button>
      </el-menu>
    </div>
    <div style="width:90%">
      <div class="row">
        <div>
          <div style="margin:20px 0 30px 0">
            <a href="./#/">
              <img src="../assets/top2.jpg" />
            </a>
          </div>

          <!-- 搜索框 -->
          <div>
            <b-input-group
              style="width:80%;height:45px; margin-bottom:30px; box-shadow: 2px 2px 5px #aaa; border-radius:5px">
              <template v-slot:prepend>
                <b-form-select v-model="selected" :options="search_type"
                  style="border-radius:5px 0 0 5px; width:12vw; height:45px"></b-form-select>
              </template>
              <b-form-input style="height:45px" placeholder="请输入检索内容" v-model="select_input"></b-form-input>
              <template v-slot:append>
                <div class="btn-group" role="group" style>
                  <b-button variant="info" style="width:5vw;height:45px;font-size:1vw" @click="film_select">检索
                  </b-button>
                  <b-button
                    style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0); width:5vw;height:45px;font-size:1vw"
                    v-show="$store.state.login_state && $store.state.current_film_page != '5'" @click="add_translation_film()">新增</b-button>
                    <b-button
                    style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0); width:5vw;height:45px;font-size:1vw"
                    v-show="$store.state.login_state && $store.state.current_film_page == '5'" @click="add_translator_film()">新增</b-button>
                  <b-button style="width:5vw;height:45px;font-size:1vw" @click="reset()">重置</b-button>
                </div>
              </template>
            </b-input-group>
          </div>
          <!-- 可视化组件 -->
          <TitlesCloud v-if="this.selected == 2 && this.$store.state.current_film_page == '1'"></TitlesCloud>
          <DirectorsCloud v-if="this.selected == 3 && this.$store.state.current_film_page == '1'"></DirectorsCloud>
          <ActorsCloud v-if="this.selected == 4 && this.$store.state.current_film_page == '1'"></ActorsCloud>
          <CountrysMap v-if="this.selected == 6 && this.$store.state.current_film_page == '1'"></CountrysMap>
          <LanguagesCloud v-if="this.selected == 5 && ['1', '2'].includes(this.$store.state.current_film_page)">
          </LanguagesCloud>
          <DirTranslationCloud v-if="this.selected == 8 && this.$store.state.current_film_page == '1'">
          </DirTranslationCloud>
          <TranslatorsCloud v-if="this.selected == 9 && this.$store.state.current_film_page == '1'"></TranslatorsCloud>
          <VoiActorsCloud v-if="this.selected == 10 && this.$store.state.current_film_page == '1'"></VoiActorsCloud>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as d3 from "d3";
import BarChart from "./home/elementvue/BarChart.vue";
import CountrysMap from "./home/elementvue/CountrysMap.vue";
import DirectorsCloud from "./home/elementvue/DirectorsCloud.vue";
import ActorsCloud from "./home/elementvue/ActorsCloud.vue"
import CountrysCloud from "./home/elementvue/CountrysCloud.vue"
import TitlesCloud from "./home/elementvue/TitlesCloud.vue"
import LanguagesCloud from "./home/elementvue/LanguagesCloud.vue"
import DirTranslationCloud from "./home/elementvue/DirTranslationCloud.vue"
import TranslatorsCloud from "./home/elementvue/TranslatorsCloud.vue"
import VoiActorsCloud from "./home/elementvue/VoiActorsCloud.vue"
export default {
  name: "FilmTranslation",
  data() {
    return {
      //记录前一条数据，当前数据，和后一条数据
      previousID:"",
      nextID:"",
      idfilms:"",
      make_sure_delete_but: false,
      make_sure_deleteclick: false,
      activeIndex: this.$store.state.current_active_index,
      perPage: 1,
      path: "",
      items: this.$store.state.current_select_item ? this.$store.state.current_select_item : 20,
      currentPage: parseInt(this.$store.state.current_page),
      total: 0,
      search_type: [
        { value: '1', text: '影片原名' },
        { value: '2', text: '大陆译名' },
        { value: '3', text: '影片导演' },
        { value: "4", text: "主要演员" },
        { value: "5", text: "原片语种" },
        { value: "6", text: "出品国家" },
        { value: "7", text: "影片类型" },
        { value: "8", text: "译制导演" },
        { value: "9", text: "译者" },
        { value: "10", text: "配音演员" },
        { value: "11", text: "译制机构" }],
      selected: this.$store.state.current_select_state,
      select_input: '',
      payload: {},
      books: [],
      base_path: 'https://api.film.yingshinet.com/film',
      current_order: '当前：时间排序'
    };
  },
  components: {
    CountrysMap,
    BarChart,
    DirectorsCloud,
    ActorsCloud,
    CountrysCloud,
    TitlesCloud,
    LanguagesCloud,
    DirTranslationCloud,
    TranslatorsCloud,
    VoiActorsCloud
  },
  methods: {
    movie_web(name){
      window.open("https://movie.yingshinet.com/#/directors?="+name.trim(),'_blank') // 在新窗口打开外链接
      // window.location.href = "https://movie.yingshinet.com/#/directors?="+name.trim();
    },
    old_order() {
      if(this.$store.state.current_path == this.base_path+1){
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1_old")
        this.current_order = '当前：默认排序'
      }else {
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1")
        this.current_order = '当前：时间排序'
      }
      this.pageChange(this.currentPage)
    },
    make_sure_delete(id){
      this.make_sure_delete_but=true
      this.idfilms=id
    },
    go_login() {
      this.$router.push("/login");
    },
    logout() {
      this.$store.commit('change_state_out')
      this.$message({
        message: '登出成功！',
        type: 'success'
      });
    },
    reset() {
      this.$store.commit("change_current_select_content", JSON.stringify(null))
      this.$store.commit("change_current_select_text", "")
      this.$store.commit('change_current_state', "0")
      this.$store.commit('change_previous_film_page', "0")
      this.select_input = null
      this.pageChange(1)
      this.currentPage = 1
    },
    film_foreign_click() {
      this.$store.commit('change_current_film_page', "1")
      this.$store.commit('change_current_active_index', "1")
      this.$store.commit('change_previous_film_page', "1")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1")
      this.current_order = '当前：时间排序'
      this.pageChange(1)
      this.currentPage = 1
    },
    film_nation_click() {
      this.$store.commit('change_current_film_page', "2")
      this.$store.commit('change_current_active_index', "2")
      this.$store.commit('change_previous_film_page', "2")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film2")
      this.pageChange(1)
      this.currentPage = 1
    },
    film_china_click() {
      this.$store.commit('change_current_film_page', "3")
      this.$store.commit('change_current_active_index', "3")
      this.$store.commit('change_previous_film_page', "3")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film3")
      this.pageChange(1)
      this.currentPage = 1
    },
    film_help_click() {
      this.$store.commit('change_current_film_page', "4")
      this.$store.commit('change_previous_film_page', "4")
      this.$store.commit('change_current_active_index', "4")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film4")
      this.pageChange(1)
      this.currentPage = 1
    },
    film_translator_click() {
      this.$store.commit('change_current_film_page', "5")
      this.$store.commit('change_previous_film_page', "5")
      this.$store.commit('change_current_active_index', "5")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film5")
      this.pageChange(1)
      this.currentPage = 1
    },
    film_novel_click() {
      this.$store.commit('change_current_film_page', "6")
      this.$store.commit('change_previous_film_page', "6")
      this.$store.commit('change_current_active_index', "6")
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film6")
      this.pageChange(1)
      this.currentPage = 1
    },

    film_select() {
      this.currentPage = 1
      this.$store.commit('change_current_page', 1)

      if (this.select_input != "") {
        switch (this.selected) {
          case "1":
            this.payload = { name_original: this.select_input }
            this.$store.commit("change_current_select_state", "1")
            this.getQueryBooks()
            break;
          case "2":
            this.payload = { transname_chi_mainland: this.select_input }
            this.$store.commit("change_current_select_state", "2")
            this.getQueryBooks()
            break;
          case "3":
            this.payload = { director_original: this.select_input }
            this.$store.commit("change_current_select_state", "3")
            this.getQueryBooks()
            break;
          case "4":
            this.payload = { actors_original: this.select_input };
            this.$store.commit("change_current_select_state", "4")
            this.getQueryBooks();
            break;
          case "5":
            this.payload = { language_original: this.select_input }
            this.$store.commit("change_current_select_state", "5")
            this.getQueryBooks()
            break;
          case "6":
            this.payload = { country_produce: this.select_input }
            this.$store.commit("change_current_select_state", "6")
            this.getQueryBooks()
            break;
          case "7":
            this.payload = { film_type: this.select_input }
            this.$store.commit("change_current_select_state", "7")
            this.getQueryBooks()
            break;
          case "8":
            this.payload = { director_translation: this.select_input }
            this.$store.commit("change_current_select_state", "8")
            this.getQueryBooks()
            break;
          case "9":
            this.payload = { translators: this.select_input }
            this.$store.commit("change_current_select_state", "9")
            this.getQueryBooks()
            break;
          case "10":
            this.payload = { voice_actors: this.select_input }
            this.$store.commit("change_current_select_state", "10")
            this.getQueryBooks()
            break;
          case "11":
            this.payload = { agency_translation: this.select_input }
            this.$store.commit("change_current_select_state", "11")
            this.getQueryBooks()
            break;
          default:
        }
      } else {
        this.reset()
      }
    },
    films(index,id) {
      this.$store.state.currentID = id
      this.$store.state.index = index
      this.$store.state.allbooks = this.books
      this.$router.push({ path: '/intro', query: { film: id}, })
    },
    add_translation_film() {
      this.$router.push("/addUpdate")
    },
    add_translator_film() {
      this.$router.push("/addNewUpdate")
    },
    edit_film_translation(film) {
      this.$router.push({ path: "/addUpdate", query: { filmID: film.idfilms } });
    },
    edit_film_translator(film) {
      this.$router.push({ path: "/addNewUpdate", query: { filmID: film.idfilms } });
    },
    delete_film_translation() {
      const path ="https://api.film.yingshinet.com/film"+this.$store.state.current_film_page+"/delete"
      axios
        .post(path,{idfilms:this.idfilms})
        .then((response) => {
          if (response.data.msg == "delete success") {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            location.reload();
          } else {
            this.$message({
              message: "删除失败",
              type: "warning",
            });
          }
        });
    },

    getQueryBooks() {
      this.$store.commit("change_current_select_content", JSON.stringify(this.payload))
      this.$store.commit("change_current_select_text", this.select_input)
      this.$store.commit('change_current_state', "1")
      this.currentPage = parseInt(this.$store.state.current_page)
      this.pageChange(this.$store.state.current_page)
    },
    pageChange(page) {
      if (this.$store.state.current_state == "0") {
        this.$store.commit('change_current_page', page)
        if(this.$store.state.current_select_item!=null){
          this.path = this.$store.state.current_path + "?page=" + page + "&offset=" + this.$store.state.current_select_item;
          console.log("this.path: "+this.path)
          // this.path = "https://api.film.yingshinet.com/film" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.$store.state.current_select_item;
          // console.log("select item: "+this.$store.state.current_select_item)
        }
        else{
          this.path = this.$store.state.current_path + "?page=" + page + "&offset=" + this.items;
          console.log("this.path: "+this.path)
          // this.path = "https://api.film.yingshinet.com/film" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.items;
          // console.log("select item: "+this.$store.state.current_select_item)
        }
        axios.get(this.path).then(res => {
          this.books = res.data.books;
          this.total = res.data.all_item_count
        }).catch(error => {
          console.error(error);
        });
      } else {
        this.$store.commit('change_current_page', page)
        if (this.$store.state.current_select_item != null) {
          this.path = "https://api.film.yingshinet.com/filmsquery" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.$store.state.current_select_item + "&divide_page=1";
          console.log("select item: " + this.$store.state.current_select_item)
        }
        else {
          this.path = "https://api.film.yingshinet.com/filmsquery" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.items + "&divide_page=1";
          console.log("select item: " + this.$store.state.current_select_item)
        }
        axios.post(this.path, this.payload).then(res => {
          this.books = res.data.books
          this.total = res.data.all_item_count
        }).catch(error => {
          console.error(error);
        });
      }
    },
    sizeChange(items) {
      this.items = items
      this.$store.commit('change_current_select_item', items)
      this.pageChange(this.currentPage)
    },
  },

  created() {
    if (this.$store.state.current_select_text == "" || this.$store.state.current_select_text == null) {
      console.log("换页")
      this.$store.commit('change_current_state', "0")
      this.pageChange(this.$store.state.current_page);
    } else {
      this.select_input = this.$store.state.current_select_text
      this.payload = JSON.parse(this.$store.state.current_select_content);
      this.getQueryBooks()
    }
  }
};
</script>
<style>
.menu .el-menu-item {
  font-size: 16px;
}

.el-menu-item {
  padding: 0 0.5vw !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  font-size: 18px;
  border-bottom: rgba(255, 255, 255, 0) !important;
}

.film_name:hover {
  cursor: pointer;
}

.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}

.image_style:hover {
  z-index: 1;
  transform: scale(3, 3);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}

.table th,
.table td {
  vertical-align: middle;
}
.old_order:hover {
  background-color: aliceblue;
}
</style>