<!-- 具体电影的介绍页面 -->
<template>
  <div class="container" style="margin: 70px 0 10vh 0">
    <!-- 导航栏 -->
    <div>
      <b-navbar toggleable="lg" type="dark" variant="info" fixed="top">
        <b-navbar-brand v-show="['1','2','3','4'].includes(this.$store.state.current_film_page)">电影详细信息</b-navbar-brand>
        <b-navbar-brand v-show="this.$store.state.current_film_page == 5">译者工作库详细信息</b-navbar-brand>
        <b-navbar-brand v-show="this.$store.state.current_film_page == 6">电影小说详细信息</b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button v-show="this.$store.state.login_state && ['1','2','3','4'].includes(this.$store.state.current_film_page)" 
                @click="go_update()" variant="info">修改</b-button>
              <b-button v-show="this.$store.state.login_state && this.$store.state.current_film_page == 5" 
                @click="go_NewUpdate()" variant="info">修改</b-button>
              <b-button v-show="this.$store.state.login_state && this.$store.state.current_film_page == 6" 
                @click="go_NovelUpdate()" variant="info">修改</b-button>
              <b-button @click="go_home()" variant="info">主页</b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div style="margin-top: 10vh">
      <div class="card" style="width: 100%">
        <!-- 外国电影汉译库 & 民族语译制库 & 中国电影外译库 & 无障碍翻译库 -->
        <b-card v-show="['1','2','3','4'].includes(this.$store.state.current_film_page)" bg-variant="light" text-variant="dark"
          border-variant="info" class="shadow rounded" style="border: 1.4px solid">
          <template v-slot:header>
            <span style="position: relative; text-align: center">
              {{ books.name_original }}
            </span>
            <span style="position: absolute; right: 5%">
              <i @click="go_home()" class="el-icon-close"></i>
            </span>
            <span style="position: absolute; left: 25%">
              <i @click="go_previous()" class="el-icon-arrow-left"></i>
            </span>
            <span style="position: absolute; right: 25%">
              <i @click="go_next()" class="el-icon-arrow-right"></i>
            </span>
          </template>
          <b-container>
            <b-row>
              <b-col cols="3.5" style="margin: 1vw 2vw 2vw 3vw; height: 24vw; width: 22vw;">
                <img style="height: 22vw; width: 22vw;" :alt="books.name_original" :src="books.filmImageFile" />
              </b-col>
              <b-col>
                <div>
                  <div class="col" style=" font-size: 1.2vw; line-height: 2.3vw; margin: 0.5vw 0; ">
                    <div>
                      <span style="color: #17a2b8">影片原名：</span>
                      <span>{{ books.name_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">原片语种：</span>
                      <span>{{ books.language_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">出品国家：</span>
                      <span>{{ books.country_produce }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">出品公司：</span>
                      <span>{{ books.producer_company_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">出品年份：</span>
                      <span>{{ books.produce_time_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">上映日期（出品国）：</span>
                      <span>{{ books.release_time_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">影片导演：</span>
                      <span>{{ books.director_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">影片编剧：</span>
                      <span>{{ books.screen_writer }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">主要演员：</span>
                      <span>{{ books.actors_original }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">影片类型：</span>
                      <span>{{ books.film_type }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">时长：</span>
                      <span>{{ books.length }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">声色：</span>
                      <span>{{ books.voice }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">广告宣发：</span>
                      <span>{{ books.ad }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="col" style=" font-size: 1.2vw; line-height: 2.3vw; margin-top: 0.5vw; ">
                    <div>
                      <span style="color: #17a2b8">大陆译名：</span>
                      <span>{{ books.transname_chi_mainland }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">港澳台译名：</span>
                      <span>{{ books.transname_chi_others }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">译制机构：</span>
                      <span>{{ books.agency_translation }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">译制导演：</span>
                      <li style="display: inline-block; color:#078a9e; cursor: pointer;"
                        v-for="(direc, index) in all_director_translation" :key="index" @click="practitioners(direc)">
                        {{ direc }} &nbsp
                      </li>
                    </div>
                    <div>
                      <span style="color: #17a2b8">译者：</span>
                      <li style="display: inline-block; color:#078a9e; cursor: pointer;"
                        v-for="(trans, index) in all_translators" :key="index" @click="practitioners(trans)">
                        {{ trans }} &nbsp
                      </li>
                    </div>
                    <div>
                      <span style="color: #17a2b8">译制方式：</span>
                      <span>{{ books.type_translation }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">翻译直接性：</span>
                      <span>{{ books.isdirectTrans }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">配音演员：</span>
                      <li style="display: inline-block; color:#078a9e;cursor: pointer;"
                        v-for="(voice, index) in all_voice_actors" :key="index" @click="practitioners(voice)">
                        {{ voice }} &nbsp
                      </li>
                    </div>
                    <div>
                      <span style="color: #17a2b8">豆瓣评分：</span>
                      <span>{{
                        books.douban_rank ? books.douban_rank : "无"
                      }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">上映日期（大陆）：</span>
                      <span>{{ books.release_time_mainland }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">上映日期（港澳台）：</span>
                      <span>{{ books.release_time_others }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">首映影院：</span>
                      <span>{{ books.cinema }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">资料来源：</span>
                      <span>{{ books.source }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">备注：</span>
                      <span>{{ books.memo }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <!-- 译制工作者库 -->
        <b-card v-show="this.$store.state.current_film_page == 5" bg-variant="light" text-variant="dark"
          border-variant="info" class="shadow rounded" style="border: 1.4px solid">
          <template v-slot:header>
            <span style="position: relative; text-align: center">
              {{ books.p_name }}-简介
            </span>
            <span style="position: absolute; right: 5%">
              <i @click="go_home()" class="el-icon-close"></i>
            </span>
            <span style="position: absolute; left: 25%">
              <i @click="go_previous()" class="el-icon-arrow-left"></i>
            </span>
            <span style="position: absolute; right: 25%">
              <i @click="go_next()" class="el-icon-arrow-right"></i>
            </span>
          </template>
          <!-- <span  @click="go_home()" class="el-icon-close"></span> -->
          <b-container>
            <b-row>
              <b-col cols="3.5" style="margin: 1vw 2vw 2vw 3vw; height: 24vw; width: 22vw;">
                <img style="height: 22vw; width: 22vw;" :alt="books.name_original" :src="books.filmImageFile" />
              </b-col>
              <b-col>
                <div>
                  <div class="col" style=" font-size: 1.2vw; line-height: 2.3vw; margin: 0.5vw 0; ">
                    <div>
                      <span style="color: #17a2b8">姓名：</span>
                      <span>{{ books.p_name }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">性别：</span>
                      <span>{{ books.p_sex }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">职业：</span>
                      <span v-show="books.isactor == 1 && books.isdirector == 1 && books.istranslator == 1">
                        配音演员/译制导演/翻译</span>
                      <span v-show="books.isactor == 1 && books.isdirector == 1 && books.istranslator == 0">
                        配音演员/译制导演</span>
                      <span v-show="books.isactor == 1 && books.isdirector == 0 && books.istranslator == 0">
                        配音演员</span>
                      <span v-show="books.isactor == 0 && books.isdirector == 1 && books.istranslator == 1">
                        译制导演/翻译</span>
                      <span v-show="books.isactor == 0 && books.isdirector == 1 && books.istranslator == 0">
                        译制导演</span>
                      <span v-show="books.isactor == 0 && books.isdirector == 0 && books.istranslator == 1">
                        翻译</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">小传：</span>
                      <span>{{ books.p_intro }}</span>
                    </div>
                    <el-card>
                      <el-container>
                        <el-container direction="vertical">
                          <el-row :gutter="20">
                            <el-col v-for="(data, i) in filteredMovieData" :key="i" class="mouse_hover"
                              style="margin: 1vh 0.5vw 1vh 0.5vw; height: 10vh; width: 5vw;">
                              {{ data }}
                              <img :src="data.filmImageFile" style="height: 10vh; width: 4vw;" />
                            </el-col>
                          </el-row>
                          <div class="btn-showMore" v-if="movieData.length > 9">
                            <el-button type="primary" @click="showImgMore = !showImgMore">{{ showImgMore ?
                              '共' +movieData.length+'部 展开更多':'收起'}}<i></i></el-button>
                          </div>
                        </el-container>
                      </el-container>
                    </el-card>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <!-- 电影小说库 -->
        <b-card v-show="this.$store.state.current_film_page == 6" bg-variant="light" text-variant="dark"
          border-variant="info" class="shadow rounded" style="border: 1.4px solid">
          <template v-slot:header>
            <span style="position: relative; text-align: center">
              {{ books.film_name }}
            </span>
            <span style="position: absolute; right: 5%">
              <i @click="go_home()" class="el-icon-close"></i>
            </span>
            <span style="position: absolute; left: 25%">
              <i @click="go_previous()" class="el-icon-arrow-left"></i>
            </span>
            <span style="position: absolute; right: 25%">
              <i @click="go_next()" class="el-icon-arrow-right"></i>
            </span>
          </template>
          <b-container>
            <b-row>
              <b-col cols="3.5" style="margin: 1vw 2vw 2vw 3vw; height: 24vw; width: 22vw;">
                <img style="margin-left: 1vw; height: 18vw; width: 18vw;" :alt="books.novel_title" :src="books.novel_image" />
                <div style="margin-top: 19vw; display: flex; justify-content: center;">
                  <template v-if="books.novel_file">
                    <b-button :href="books.novel_file" variant="primary" 
                      style="background-color: #0dcaf0; border-color: #17a2b8">
                      下载 PDF
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button variant="primary" disabled
                      style="background-color: #0dcaf0; border-color: #17a2b8">
                      暂无 PDF 文件
                    </b-button>
                  </template>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="col" style=" font-size: 1.2vw; line-height: 2.3vw; margin: 0.5vw 0; ">
                    <div>
                      <span style="color: #17a2b8">导演：</span>
                      <span>{{ books.film_director }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">编剧 / 改编者：</span>
                      <span>{{ books.film_adaptor }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">主演：</span>
                      <span>{{ books.film_actors }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">国别：</span>
                      <span>{{ books.film_country }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">出品方：</span>
                      <span>{{ books.film_producer_company }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">上映日期：</span>
                      <span>{{ books.film_release_time }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="col" style=" font-size: 1.2vw; line-height: 2.3vw; margin-top: 0.5vw; ">
                    <div>
                      <span style="color: #17a2b8">电影小说：</span>
                      <span>{{ books.novel_title }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">作者 / 改编者：</span>
                      <span>{{ books.novel_author }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">文体：</span>
                      <span>{{ books.novel_type }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">发表报刊 / 出版单位：</span>
                      <span>{{ books.novel_publish_company }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">发表 / 出版时间：</span>
                      <span>{{ books.novel_release_time }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">字数（字）：</span>
                      <span>{{ books.novel_word_count }}</span>
                    </div>
                    <div>
                      <span style="color: #17a2b8">备注：</span>
                      <span>{{ books.novel_note }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      info: "",
      books: [],
      header: "电影详情",
      idfilms: 1,
      items: this.$store.state.current_select_item ? this.$store.state.current_select_item : 20,
      allbooks: [],
      movieData: [],
      showImgMore: true,
      all_voice_actors: [],
      all_translators: [],
      all_director_translation: [],
      index: "",
    };
  },
  methods: {
    getposter() {
      axios.post(
        "https://api.film.yingshinet.com/filmsqueryfromforeign",
        { p_name: this.books.p_name }
      )
        .then((resp) => {
          this.movieData = (resp.data.books)
          console.log(this.movieData.length)
        })
    },
    practitioners(voice) {
      console.log(voice)
      axios
        .post("https://api.film.yingshinet.com/actors_query", { p_name: voice })
        .then((resp) => {
          // console.log(resp.data.books);
          let p_id = resp.data.books.idfilms;
          this.$store.state.previous_film_page = this.$store.state.current_film_page
          this.$store.state.current_film_page = 5;
          this.$store.state.currentID = p_id;
          axios
            .post("https://api.film.yingshinet.com/filmsquery" + 5, {
              idfilms: p_id,
            })
            .then((resp) => {
              // console.log(resp.data.books[0])
              this.books = resp.data.books[0];
              this.all_voice_actors = this.books.voice_actors;
            });
        });
    },

    go_previous() {
      if (this.index != 0) {
        this.$store.state.previousID = this.allbooks[this.index - 1].idfilms;
      } else {
        this.$store.state.previousID = -1;
      }
      if (this.$store.state.previousID != -1) {
        this.$store.state.index = this.index - 1;
        this.$store.state.currentID = this.$store.state.previousID;
        axios
          .post(
            "https://api.film.yingshinet.com/filmsquery" +
            this.$store.state.current_film_page,
            { idfilms: this.$store.state.previousID }
          )
          .then((resp) => {
            this.books = resp.data.books[0];
            this.index = this.$store.state.index;
          });
      }
    },
    go_next() {
      //如果不是最后一条
      if (this.index != this.items - 1) {
        this.$store.state.nextID = this.allbooks[this.index + 1].idfilms;
      } else {
        this.$store.state.nextID = -1;
      }
      if (this.$store.state.nextID != -1) {
        this.$store.state.index = this.index + 1;
        this.$store.state.currentID = this.$store.state.nextID;
        axios
          .post(
            "https://api.film.yingshinet.com/filmsquery" +
            this.$store.state.current_film_page,
            { idfilms: this.$store.state.nextID }
          )
          .then((resp) => {
            this.books = resp.data.books[0];
            this.index = this.$store.state.index;
          });
      }
    },
    go_home() {
      // console.log( this.$store.state.previous_film_page)
      this.$store.state.current_film_page = this.$store.state.previous_film_page
      this.$router.go(-1);
    },
    go_update() {
      this.$router.push({
        path: "/addUpdate",
        query: { filmID: this.$store.state.currentID },
      });
    },
    go_NewUpdate() {
      this.$router.push({
        path: "/addNewUpdate",
        query: { filmID: this.$store.state.currentID },
      });
    },
    go_NovelUpdate() {
      this.$router.push({
        path: "/addNovelUpdate",
        query: { filmID: this.$store.state.currentID },
      });
    },
  },
  computed: {
    filteredMovieData() {
      if (this.showImgMore) {
        return this.movieData.slice(0, 9);
      } else {
        return this.movieData; // 或者根据你需要的条件来过滤
      }
    }
  },
  created() {
    let url = window.location.href;
    this.$store.state.currentID = url.split("=")[url.split("=").length - 1];
    if (this.$store.state.currentID != -1) {
      this.index = this.$store.state.index;
      this.allbooks = this.$store.state.allbooks;
      axios
        .post(
          "https://api.film.yingshinet.com/filmsquery" +
          this.$store.state.current_film_page,
          { idfilms: this.$store.state.currentID }
        )
        .then((resp) => {
          this.books = resp.data.books[0];
          if (['1','2','3','4'].includes(this.$store.state.current_film_page)) {
            if (this.books.voice_actors != null && this.books.voice_actors.includes('/')) {
              this.all_voice_actors = this.books.voice_actors.split("/");
            }
            else { this.all_voice_actors.push(this.books.voice_actors) }
            if (this.books.translators != null && this.books.translators.includes('/')) {
              this.all_translators = this.books.translators.split("/");
            }
            else { this.all_translators.push(this.books.translators) }
            if (this.books.director_translation != null && this.books.director_translation.includes('/')) {
              this.all_director_translation = this.books.director_translation.split("/");
            }
            else { this.all_director_translation.push(this.books.director_translation) }

          }
          else {
            this.getposter();
          }
        });
    }
    else {
      console.log("有点问题");
    }
  },
};
</script>
<style>
.container {
  max-width: 98vw;
}

.card-header {
  color: #1795a8;
  font-size: 25px;
  width: 100%;
  background-color: #f7f7f7;
  text-align: center;
}

.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}

.closebut {
  float: right;
}
</style>
