<template>
  <div>
    <!-- header -->
    <div class="header_height">
      <img src="../assets/top2.jpg" class="header_image" />
      <b-button
        @click="go_login()"
        variant="info"
        style="float: right; margin: 25px 20px 0 0; background-color:#F0755C; border-color:rgba(255, 255, 255, 0)"
        v-if="!$store.state.login_state"
      >登录</b-button>
      <b-button
        @click="logout()"
        variant="info"
        style="float: right; margin: 25px 20px 0 0; background-color:#F0755C; border-color:rgba(255, 255, 255, 0)"
        v-else
      >登出</b-button>
      <b-button
        @click="go_film_default()"
        variant="info"
        style="float: right; margin: 25px 20px 0 0"
      >翻译库</b-button>
      <b-button
        @click="handlecloud('line')"
        variant="info"
        style="float: right; margin: 25px 20px 0 0"
        v-show="this.$store.state.isvis == 'picture'"
      >年代影像</b-button>
      <b-button
        @click="handlecloud('picture')"
        variant="info"
        style="float: right; margin: 25px 20px 0 0"
        v-show="this.$store.state.isvis == 'line'"
      >照片墙</b-button>
    </div>
    <!-- 搜索框 -->
    <div align="center">
      <b-input-group
        style="
          width: 80%;
          height: 45px;
          margin: 30px 0;
          box-shadow: 2px 2px 5px #aaa;
          border-radius: 5px;
        "
      >
        <template v-slot:prepend>
          <b-form-select
            v-model="content_select"
            :options="search_content"
            style="border-radius: 5px 0 0 5px; width: 10vw; height: 45px"
          ></b-form-select>
          <b-form-select
            v-model="selected"
            :options="search_type"
            style="border-radius: 0; width: 10vw; height: 45px"
          ></b-form-select>
        </template>
        <b-form-input style="height: 45px" placeholder="请输入检索内容" v-model="select_input"></b-form-input>
        <template v-slot:append>
          <div class="btn-group" role="group">
            <b-button
              variant="info"
              style="width: 10vw; height: 45px; font-size: 1vw"
              @click="film_select"
            >检索</b-button>
          </div>
        </template>
      </b-input-group>
    </div>
    <!-- 照片墙 -->
    <div v-if="this.$store.state.isvis == 'picture'" class="pic_border" align="center">
      <span v-for="(movie_poster, index) in movie_posters" :key="index" class="eliminate_margin">
        <el-tooltip class="item" effect="dark" :content="'《' + movie_poster.name + '》上映日期：' + movie_poster.release_time_original" placement="top-start">
          <b-img-lazy
            :src="movie_poster.filmImageFile"
            :alt="movie_poster.transname_chi_mainland"
            :title="movie_poster.transname_chi_mainland"
            @click.native="movie_poster_click(index,movie_poster.id)"
            class="home_image_style"
          ></b-img-lazy>
        </el-tooltip>
      </span>
    </div>
    <VideoGraph v-if="this.$store.state.isvis == 'line'"></VideoGraph>
    <!-- 网站数据 -->
    <div style="margin: 5vh 15vw 4vh 15vw">
      <div class="title" align="center">
        <h2></h2>
      </div>
      <el-row>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('1')" />
          </div>
          <div class="data_font_size">外国电影汉译库</div>
          <div class="data_font_size">{{ foreign_film_count }} 条</div>
        </el-col>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('2')" />
          </div>
          <div class="data_font_size">民族语译制库</div>
          <div class="data_font_size">{{ nation_film_count }} 条</div>
        </el-col>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('3')" />
          </div>
          <div class="data_font_size">中国电影外译库</div>
          <div class="data_font_size">{{ china_film_count }} 条</div>
        </el-col>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('4')" />
          </div>
          <div class="data_font_size">无障碍翻译库</div>
          <div class="data_font_size">{{ help_film_count }} 条</div>
        </el-col>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('6')" />
          </div>
          <div class="data_font_size">电影小说库</div>
          <div class="data_font_size">{{ novel_film_count }} 条</div>
        </el-col>
        <el-col :span="4" align="center">
          <div>
            <img src="../assets/film_count.png" class="icon_style" @click="go_film('5')" />
          </div>
          <div class="data_font_size">译制工作者库</div>
          <div class="data_font_size">{{ worker_film_count }} 条</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VideoGraph from './home/elementvue/Video_index_graph.vue'
export default {
  components: {
    VideoGraph,
  },
  data() {
    return {
      // showvis: false,
      send_books: [],
      search_type: [
        { value: "1", text: "影片原名" },
        { value: "2", text: "影片译名" },
        { value: "3", text: "影片导演" },
        { value: "4", text: "主要演员" },
        { value: "5", text: "原片语种" },
        { value: "6", text: "原产国家" },
        { value: "7", text: "影片类型" },
        { value: "8", text: "译制导演" },
        { value: "9", text: "译者" },
        { value: "10", text: "配音演员" },
        { value: "11", text: "译制机构" },
      ],
      search_content: [
        { value: "1", text: "外国电影汉译库" },
        { value: "2", text: "民族语译制库" },
        { value: "3", text: "中国电影外译库" },
        { value: "4", text: "无障碍翻译库" },
      ],
      selected: "2",
      content_select: "1",
      payload: {},
      movie_posters: [],
      select_input: "",
      foreign_film_count: 0,
      nation_film_count: 0,
      china_film_count: 0,
      help_film_count: 0,
      worker_film_count: 0,
      novel_film_count: 0,
    };
  },
  methods: {
    handlecloud(isvis) {
      this.$store.commit("handlevis", isvis);
    },
    go_film_default() {
      this.$store.commit("change_current_select_content", JSON.stringify(null))
      this.$store.commit("change_current_select_text", "")
      this.$router.push("/filmTranslation");
    },
    go_film(index) {
      this.$store.commit("change_current_select_content", JSON.stringify(null))
      this.$store.commit("change_current_select_text", "")
      this.$store.commit('change_current_active_index', index)
      this.$store.commit('change_current_film_page', index)
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film"+index)
      this.$router.push("/filmTranslation");
    },
    go_login() {
      this.$router.push("/login");
    },
    logout() {
      this.$store.commit('change_state_out')
      this.$message({
        message: '登出成功！',
        type: 'success'
      });
    },
    film_select() {
      if (this.select_input != "") {
        switch (this.selected) {
          case "1":
            this.payload = { name_original: this.select_input };
            this.getQueryBooks("1");
            break;
          case "2":
            this.payload = { transname_chi_mainland: this.select_input };
            this.getQueryBooks("2");
            break;
          case "3":
            this.payload = { director_original: this.select_input };
            this.getQueryBooks("3");
            break;
          case "4":
            this.payload = { actors_original: this.select_input };
            this.getQueryBooks("4");
            break;
          case "5":
            this.payload = { language_original: this.select_input };
            this.getQueryBooks("5");
            break;
          case "6":
            this.payload = { country_produce: this.select_input };
            this.getQueryBooks("6");
            break;
          case "7":
            this.payload = { film_type: this.select_input };
            this.getQueryBooks("7");
            break;
          case "8":
            this.payload = { director_translation: this.select_input }
            this.getQueryBooks("8")
            break;
          case "9":
            this.payload = { translators: this.select_input }
            this.getQueryBooks("9")
            break;
          case "10":
            this.payload = { voice_actors: this.select_input }
            this.getQueryBooks("10")
            break;
          case "11":
            this.payload = { agency_translation: this.select_input }
            this.getQueryBooks("11")
            break;
          default:
        }
      } else {
        this.$message({
          message: '请输入搜索内容！',
          type: 'warning'
        })
      }
    },
    getQueryBooks(select_state) {
      this.$store.commit('change_current_film_page', this.content_select)
      this.$store.commit('change_current_active_index', this.content_select)
      this.$store.commit("change_current_select_content", JSON.stringify(this.payload))
      this.$store.commit("change_current_select_text", this.select_input)
      this.$store.commit("change_current_select_state", select_state)
      this.$router.push("/filmTranslation");
    },
    movie_poster_click(index,idfilms) {
      this.$store.state.currentID = idfilms
      this.$store.state.index = index
      this.$store.state.allbooks = this.movie_posters
      this.$store.commit('change_current_film_page', '1')
      this.$store.commit('change_current_active_index', "1")
      this.$router.push({ path: "/intro", query: { film: idfilms } });
    },
  },

  created() {
    axios.post("https://api.film.yingshinet.com/film1_pictures", { count: 64 }).then((response) => {
      this.movie_posters = response.data.pictures;
      console.log(this.movie_posters)
    });
    const path = "https://api.film.yingshinet.com/film"
    axios.get(path + '1').then(res => {
      this.foreign_film_count = res.data.all_item_count
    })
    axios.get(path + '2').then(res => {
      this.nation_film_count = res.data.all_item_count
    })
    axios.get(path + '3').then(res => {
      this.china_film_count = res.data.all_item_count
    })
    axios.get(path + '4').then(res => {
      this.help_film_count = res.data.all_item_count
    })
    axios.get(path + '5').then(res => {
      this.worker_film_count = res.data.all_item_count
    })
    axios.get(path + '6').then(res => {
      this.novel_film_count = res.data.all_item_count
    })
  },
};
</script>
<style>
#app {
  margin-top: 0;
}
.header_height {
  height: 94px;
  border-bottom: 4px solid #f1826b;
  box-shadow: 2px 2px 10px #aaaaaa;
}
.header_image {
  height: 70px;
  margin-top: 10px;
  margin-left: 5px;
}
.eliminate_margin {
  font-size: 0px;
}
.home_image_style {
  width: 6.12vw;
  height: 8.7vw;
  object-fit: fill;
}
.home_image_style:hover {
  z-index: 1;
  transform: scale(1.5, 1.5);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}
.pic_border {
  font-size: 0;
  margin: 0.5vw 0vw 0 0;
}
.icon_style {
  width: 4vw;
  height: 4vw;
  margin-bottom: 1vw;
}
.icon_style:hover {
  cursor: pointer;
  transform: scale(1.2, 1.2);
  transition: 0.5s transform;
}
.data_font_size {
  font-size: 1.3vw;
  color: #6c6d70;
}
.top_icon_style {
  float: right;
  margin: 25px 20px 0 0;
  font-size: 30px;
  color: #e2a253;
  cursor: pointer;
}
</style>