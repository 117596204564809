<template>
  <div style="width: 66vw; margin: 30px 0 0 17vw">
    <h3 align="center" style="color: #17a2b8">{{ title }}</h3>
    <el-form
      v-show="
        this.$store.state.current_film_page != 3 &&
        this.$store.state.current_film_page != 5 &&
        this.$store.state.current_film_page != 2
      "
      ref="form"
      :rules="rules"
      :model="form"
      label-width="100px"
    >
      <p style="font-size: 20px; color: #17a2b8">电影信息</p>
      <el-form-item
        label="影片原名"
        prop="name_original"
        style="width: 70%; display: inline-block"
      >
        <el-input v-model="form.name_original"></el-input>
      </el-form-item>
      <el-form-item style="font-size: 1vw; display: inline-block; display: right">
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
      </el-form-item>
      <el-form-item label="海报">
        <div style="display: inline-block; vertical-align: top">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :limit="1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
          <i
            class="el-icon-delete"
            margin-left="60%"
            @click="deletepic(form.idfilms)"
          ></i>
        </div>
        <el-image
          v-if="title == '修改影片信息' && form.filmImageFile"
          style="
            border-radius: 2%;
            display: inline-block;
            vertical-align: top;
            height: 150px;
            margin-left: 100px;
          "
          :preview-src-list="[form.filmImageFile]"
          class="update_image"
          :alt="1"
          :src="form.filmImageFile"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="原片语种" style="width: 50%; display: inline-block">
        <el-input v-model="form.language_original"></el-input>
      </el-form-item>
      <el-form-item label="出品国家" style="width: 50%; display: inline-block">
        <el-input v-model="form.country_produce"></el-input>
      </el-form-item>
      <el-form-item label="出品公司" style="width: 50%; display: inline-block">
        <el-input v-model="form.producer_company_original"></el-input>
      </el-form-item>
      <el-form-item label="出品年份" style="width: 50%; display: inline-block">
        <el-input v-model="form.produce_time_original"></el-input>
        <!-- <el-date-picker
          type="year"
          placeholder="出品年份"
          v-model="form.produce_time_original"
          style="width: 100%"
          value-format="yyyy"
        ></el-date-picker> -->
      </el-form-item>
      <el-form-item label="影片导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_original"></el-input>
      </el-form-item>
      <el-form-item label="主要演员">
        <el-input v-model="form.actors_original"></el-input>
      </el-form-item>
      <el-form-item label="影片类型">
        <el-input v-model="form.film_type"></el-input>
        <!-- <el-checkbox-group v-model="form.film_type">
                    <el-checkbox
                        v-for="(film_type, index) in film_types"
                        :label="film_type"
                        name="type"
                        :key="index"
                    ></el-checkbox>
                </el-checkbox-group> -->
      </el-form-item>
      <el-form-item label="时长" style="width: 50%; display: inline-block">
        <el-input v-model="form.length"></el-input>
      </el-form-item>
      <el-form-item label="声色" style="width: 50%; display: inline-block">
        <el-input v-model="form.voice"></el-input>
        <!-- <el-radio-group v-model="form.voice">
                    <el-radio label="有声"></el-radio>
                    <el-radio label="无声"></el-radio>
                </el-radio-group> -->
      </el-form-item>
      <el-form-item label="上映日期（出品国）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="出品地区上映日期"
                    v-model="form.release_time_original"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
        <el-input v-model="form.release_time_original"></el-input>
      </el-form-item>
      <el-form-item label="广告宣发">
        <el-input type="textarea" v-model="form.ad"></el-input>
      </el-form-item>
      <p style="font-size: 20px; color: #17a2b8">译制信息</p>
      <el-form-item label="大陆译名" style="width: 50%; display: inline-block">
        <el-input v-model="form.transname_chi_mainland"></el-input>
      </el-form-item>
      <el-form-item label="上映日期（大陆）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="大陆上映日期"
                    v-model="form.release_time_mainland"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
      <el-input v-model="form.release_time_mainland"></el-input>
      </el-form-item>
      <el-form-item label="进口国家" style="width: 50%; display: inline-block">
        <el-input v-model="form.country_import"></el-input>
      </el-form-item>
      <el-form-item label="译者" style="width: 50%; display: inline-block">
        <el-input v-model="form.translators"></el-input>
      </el-form-item>
      <el-form-item label="译制导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制机构" style="width: 50%; display: inline-block">
        <el-input v-model="form.agency_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制方式" style="width: 50%; display: inline-block">
        <el-input v-model="form.type_translation"></el-input>
      </el-form-item>
      <el-form-item label="配音演员">
        <el-input v-model="form.voice_actors"></el-input>
      </el-form-item>
      <el-form-item label="首映影院" style="width: 50%; display: inline-block">
        <el-input v-model="form.cinema"></el-input>
      </el-form-item>
      <el-form-item
        v-show="this.$store.state.current_film_page == 1"
        label="翻译直接性"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.isdirectTrans"></el-input>
      </el-form-item>
      <el-form-item label="豆瓣评分" style="width: 50%">
        <el-input v-model="form.douban_rank"></el-input>
      </el-form-item>
      <el-form-item label="港澳台译名" style="width: 50%; display: inline-block">
        <el-input v-model="form.transname_chi_others"></el-input>
      </el-form-item>
      <el-form-item label="上映日期（港澳台）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="港澳台上映日期"
                    v-model="form.release_time_others"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
        <el-input v-model="form.release_time_others"></el-input>
      </el-form-item>
      <el-form-item label="英文译名" style="width: 50%">
        <el-input v-model="form.transname_english"></el-input>
      </el-form-item>
      <p style="font-size: 20px; color: #17a2b8">其他信息</p>
      <el-form-item label="进口方式">
        <el-input v-model="form.import_way"></el-input>
        <!-- <el-radio-group v-model="form.import_way">
                    <el-radio label="买断"></el-radio>
                    <el-radio label="分账"></el-radio>
                </el-radio-group> -->
      </el-form-item>
      <el-form-item label="资料来源">
        <el-input v-model="form.source"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="form.memo"></el-input>
      </el-form-item>
      <el-form-item label="推荐">
        <el-switch v-model="recommend"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
        <el-button @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>

    <el-form
      v-show="this.$store.state.current_film_page == 2"
      ref="form"
      :rules="rules"
      :model="form"
      label-width="100px"
    >
      <p style="font-size: 20px; color: #17a2b8">电影信息</p>
      <el-form-item
        label="影片原名"
        prop="name_original"
        style="width: 70%; display: inline-block"
      >
        <el-input v-model="form.name_original"></el-input>
      </el-form-item>
      <el-form-item style="font-size: 1vw; display: inline-block; display: right">
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
      </el-form-item>
      <el-form-item label="海报">
        <div style="display: inline-block; vertical-align: top">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :limit="1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
          <i
            class="el-icon-delete"
            margin-left="60%"
            @click="deletepic(form.idfilms)"
          ></i>
        </div>
        <el-image
          v-if="title == '修改影片信息' && form.filmImageFile"
          style="
            border-radius: 2%;
            display: inline-block;
            vertical-align: top;
            height: 150px;
            margin-left: 100px;
          "
          :preview-src-list="[form.filmImageFile]"
          class="update_image"
          :alt="1"
          :src="form.filmImageFile"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="原片语种" style="width: 50%; display: inline-block">
        <el-input v-model="form.language_original"></el-input>
      </el-form-item>
      <el-form-item label="出品国家" style="width: 50%; display: inline-block">
        <el-input v-model="form.country_produce"></el-input>
      </el-form-item>
      <el-form-item label="出品公司" style="width: 50%; display: inline-block">
        <el-input v-model="form.producer_company_original"></el-input>
      </el-form-item>
      <el-form-item label="出品年份" style="width: 50%; display: inline-block">
        <el-input v-model="form.produce_time_original"></el-input>
        <!-- <el-date-picker
          type="year"
          placeholder="出品年份"
          v-model="form.produce_time_original"
          style="width: 100%"
          value-format="yyyy"
        ></el-date-picker> -->
      </el-form-item>
      <el-form-item label="影片导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_original"></el-input>
      </el-form-item>
      <el-form-item label="主要演员">
        <el-input v-model="form.actors_original"></el-input>
      </el-form-item>
      <el-form-item label="影片类型">
        <el-input v-model="form.film_type"></el-input>
        <!-- <el-checkbox-group v-model="form.film_type">
                    <el-checkbox
                        v-for="(film_type, index) in film_types"
                        :label="film_type"
                        name="type"
                        :key="index"
                    ></el-checkbox>
                </el-checkbox-group> -->
      </el-form-item>
      <el-form-item label="时长" style="width: 50%; display: inline-block">
        <el-input v-model="form.length"></el-input>
      </el-form-item>
      <el-form-item label="声色" style="width: 50%; display: inline-block">
        <el-input v-model="form.voice"></el-input>
        <!-- <el-radio-group v-model="form.voice">
                    <el-radio label="有声"></el-radio>
                    <el-radio label="无声"></el-radio>
                </el-radio-group> -->
      </el-form-item>
      <el-form-item label="上映日期（出品国）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="出品地区上映日期"
                    v-model="form.release_time_original"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
        <el-input v-model="form.release_time_original"></el-input>
      </el-form-item>
      <el-form-item label="广告宣发">
        <el-input type="textarea" v-model="form.ad"></el-input>
      </el-form-item>
      <p style="font-size: 20px; color: #17a2b8">译制信息</p>
      <el-form-item
        label="少数民族语译名"
        label-width="120px"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.transname_chi_mainland"></el-input>
      </el-form-item>
      <el-form-item
        label="译入少数民族语种"
        label-width="120px"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.language_original"></el-input>
      </el-form-item>
      <el-form-item label="上映日期（少数民族地区）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="大陆上映日期"
                    v-model="form.release_time_mainland"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
        <el-input v-model="form.release_time_mainland"></el-input>
      </el-form-item>
      <el-form-item
        label="译者"
        label-width="120px"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.translators"></el-input>
      </el-form-item>
      <el-form-item label="译制导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制机构" style="width: 50%; display: inline-block">
        <el-input v-model="form.agency_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制方式" style="width: 50%; display: inline-block">
        <el-input v-model="form.type_translation"></el-input>
      </el-form-item>
      <el-form-item label="配音演员">
        <el-input v-model="form.voice_actors"></el-input>
      </el-form-item>
      <el-form-item
        v-show="this.$store.state.current_film_page == 1"
        label="翻译直接性"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.isdirectTrans"></el-input>
      </el-form-item>
      <el-form-item label="豆瓣评分" style="width: 50%">
        <el-input v-model="form.douban_rank"></el-input>
      </el-form-item>

      <p style="font-size: 20px; color: #17a2b8">其他信息</p>
      <el-form-item label="资料来源">
        <el-input v-model="form.source"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="form.memo"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
        <el-button @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>

    <el-form
      v-show="this.$store.state.current_film_page == 3"
      ref="form"
      :rules="rules"
      :model="form"
      label-width="100px"
    >
      <p style="font-size: 20px; color: #17a2b8">电影信息</p>
      <el-form-item
        label="影片原名"
        prop="name_original"
        style="width: 70%; display: inline-block"
      >
        <el-input v-model="form.name_original"></el-input>
      </el-form-item>
      <el-form-item style="font-size: 1vw; display: inline-block; display: right">
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
      </el-form-item>
      <el-form-item label="海报">
        <div style="display: inline-block; vertical-align: top">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :limit="1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
          <i
            class="el-icon-delete"
            margin-left="60%"
            @click="deletepic(form.idfilms)"
          ></i>
        </div>
        <el-image
          v-if="title == '修改影片信息' && form.filmImageFile"
          style="
            border-radius: 2%;
            display: inline-block;
            vertical-align: top;
            height: 150px;
            margin-left: 100px;
          "
          :preview-src-list="[form.filmImageFile]"
          class="update_image"
          :alt="1"
          :src="form.filmImageFile"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="原片语种" style="width: 50%; display: inline-block">
        <el-input v-model="form.language_original"></el-input>
      </el-form-item>
      <el-form-item label="出品国家" style="width: 50%; display: inline-block">
        <el-input v-model="form.country_produce"></el-input>
      </el-form-item>
      <el-form-item label="出品公司" style="width: 50%; display: inline-block">
        <el-input v-model="form.producer_company_original"></el-input>
      </el-form-item>
      <el-form-item label="出品年份" style="width: 50%; display: inline-block">
        <el-input v-model="form.produce_time_original"></el-input>
        <!-- <el-date-picker
          type="year"
          placeholder="出品年份"
          v-model="form.produce_time_original"
          style="width: 100%"
          value-format="yyyy"
        ></el-date-picker> -->
      </el-form-item>
      <el-form-item label="影片导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_original"></el-input>
      </el-form-item>
      <el-form-item label="影片编剧" style="width: 50%; display: inline-block">
        <el-input v-model="form.screen_writer"></el-input>
      </el-form-item>
      <el-form-item label="主要演员">
        <el-input v-model="form.actors_original"></el-input>
      </el-form-item>
      <el-form-item label="影片类型">
        <el-input v-model="form.film_type"></el-input>
        <!-- <el-checkbox-group v-model="form.film_type">
                    <el-checkbox
                        v-for="(film_type, index) in film_types"
                        :label="film_type"
                        name="type"
                        :key="index"
                    ></el-checkbox>
                </el-checkbox-group> -->
      </el-form-item>
      <el-form-item label="时长" style="width: 50%; display: inline-block">
        <el-input v-model="form.length"></el-input>
      </el-form-item>
      <el-form-item label="声色" style="width: 50%; display: inline-block">
        <el-input v-model="form.voice"></el-input>
        <!-- <el-radio-group v-model="form.voice">
                    <el-radio label="有声"></el-radio>
                    <el-radio label="无声"></el-radio>
                </el-radio-group> -->
      </el-form-item>
      <el-form-item label="上映日期（出品国）" style="width: 50%; display: inline-block">
        <!-- <el-date-picker
                    type="date"
                    placeholder="出品地区上映日期"
                    v-model="form.release_time_original"
                    style="width: 100%;"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                ></el-date-picker> -->
        <el-input v-model="form.release_time_original"></el-input>
      </el-form-item>
      <el-form-item label="广告宣发">
        <el-input type="textarea" v-model="form.ad"></el-input>
      </el-form-item>
      <p style="font-size: 20px; color: #17a2b8">译制信息</p>
      <el-form-item label="外文译名" style="width: 50%; display: inline-block">
        <el-input v-model="form.transname_chi_mainland"></el-input>
      </el-form-item>
      <el-form-item label="外译语种" style="width: 50%; display: inline-block">
        <el-input v-model="form.language_aim"></el-input>
      </el-form-item>
      <el-form-item label="落地国" style="width: 50%; display: inline-block">
        <el-input v-model="form.country_import"></el-input>
      </el-form-item>
      <el-form-item
        label="上映日期（落地国）"
        label-width="140px"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.release_time_mainland"></el-input>
      </el-form-item>
      <el-form-item label="译者" style="width: 50%; display: inline-block">
        <el-input v-model="form.translators"></el-input>
      </el-form-item>
      <el-form-item label="译制导演" style="width: 50%; display: inline-block">
        <el-input v-model="form.director_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制机构" style="width: 50%; display: inline-block">
        <el-input v-model="form.agency_translation"></el-input>
      </el-form-item>
      <el-form-item label="译制方式" style="width: 50%; display: inline-block">
        <el-input v-model="form.type_translation"></el-input>
      </el-form-item>
      <!-- <el-form-item label="首映影院" style="width: 50%; display: inline-block">
        <el-input v-model="form.cinema"></el-input>
      </el-form-item> -->
      <el-form-item label="配音演员" style="width: 50%; display: inline-block">
        <el-input v-model="form.voice_actors"></el-input>
      </el-form-item>
      <el-form-item
        v-show="this.$store.state.current_film_page == 1"
        label="翻译直接性"
        style="width: 50%; display: inline-block"
      >
        <el-input v-model="form.isdirectTrans"></el-input>
      </el-form-item>
      <el-form-item label="豆瓣评分" style="width: 50%; display: inline-block">
        <el-input v-model="form.douban_rank"></el-input>
      </el-form-item>
      <el-form-item label="IMDB评分" style="width: 50%; display: inline-block">
        <el-input v-model="form.IMDB"></el-input>
      </el-form-item>
      <!-- <el-form-item label="港澳译名" style="width: 50%; display: inline-block">
        <el-input v-model="form.transname_chi_others"></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="进口国家" style="width: 50%;display: inline-block">
        <el-input v-model="form.country_import"></el-input>
      </el-form-item>
      <el-form-item label="英文译名" style="width: 50%;display: inline-block">
        <el-input v-model="form.transname_english"></el-input>
      </el-form-item> -->
      <p style="font-size: 20px; color: #17a2b8">其他信息</p>

      <el-form-item label="其他译名">
        <el-input v-model="form.transname_chi_others"></el-input>
      </el-form-item>
      <el-form-item label="其他译者">
        <el-input v-model="form.translators_other"></el-input>
      </el-form-item>
      <el-form-item label="备注" style="width: 100%; display: inline-block">
        <el-input v-model="form.memo"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
        <el-button @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      rules: {
        // name_original: [{ required: true, message: '影片名必填字段', trigger: 'blur' }]
      },
      title: "",
      path: "",
      image_path: "",
      poster: null,
      form: {
        user_name: "",
        name_original: "",
        language_original: "",
        country_produce: "",
        producer_company_original: "",
        produce_time_original: "",
        director_original: "",
        actors_original: "",
        screen_writer: "",
        //! film_type: [],
        film_type: "",
        length: "",
        voice: "",
        cinema: "",
        release_time_original: "",
        ad: "",
        transname_chi_mainland: "",
        translators: "",
        director_translation: "",
        agency_translation: "",
        type_translation: "",
        release_time_mainland: "",
        voice_actors: "",
        douban_rank: null,
        IMDB: null,
        transname_chi_others: "",
        translators_other: "",
        release_time_others: "",
        country_import: "",
        transname_english: "",
        import_way: "",
        recommend: "",
        memo: "",
        source: "",
        filmImageFile: "",
        isdirectTrans: "",
        language_aim: "",
        f_delete: 0,
      },
      recommend: false,
      //! film_types: ['剧情', '惊悚', '犯罪', '喜剧', '科幻', '动作', '悬疑', '冒险', '爱情', '战争', '历史', '音乐', '日常', '动画'],
      dialogImageUrl: "",
      dialogVisible: false,
      loading: false,
      submit: "",
      add_update_type: 0,
    };
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.recommend == true) {
            this.form.recommend = "推荐";
          }
          this.add_update_film(0);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    add_update_film(ifdelete) {
      this.loading = true;
      axios
        .post(this.path, this.form)
        .then((res) => {
          this.$notify({
            title: "提示",
            message: "信息添加成功！",
            type: "success",
          });
          if (this.poster == null && ifdelete != 1) {
            this.$router.go(-1);
          } else {
            let file = this.poster;

            let poster_param = new FormData();
            let config = { headers: { "Content-Type": "multipart/form-data" } };
            poster_param.append("file", file);
            if (ifdelete == 1) {
              poster_param.append("msg", "delete");
            } else {
              poster_param.append("msg", "nodelete");
            }
            if (this.add_update_type) {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                this.$route.query.filmID;
            } else {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                res.data.id;
            }

            axios
              .post(this.image_path, poster_param, config)
              .then((response) => {
                if (response.data.status == 1) {
                  this.$notify({
                    title: "提示",
                    message: "照片修改成功！",
                    type: "success",
                  });
                  this.$router.go(-1);
                } else {
                  this.$notify({
                    title: "提示",
                    message: "照片上传失败！",
                    type: "warning",
                  });
                }
              });
          }
        })
        .catch((error) => {
          this.$notify({
            title: "提示",
            message: "信息上传失败（数据格式可能有问题）！",
            type: "warning",
          });
          this.loading = false;
        });
    },

    handleChange(file, fileList) {
      this.poster = file.raw;
    },
    handleRemove(file, fileList) {
      this.poster = null;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    deletepic(id) {
      this.$confirm("确认要删除当前海报吗？", "提示", { type: "warning" })
        .then(() => {
          this.add_update_film(1);
        })
        .catch(() => {});
    },
    deleteDataById(id) {},
  },
  created() {
    if (this.$route.query.filmID != undefined) {
      axios
        .post(
          "https://api.film.yingshinet.com/filmsquery" +
            this.$store.state.current_film_page,
          { idfilms: this.$route.query.filmID }
        )
        .then((response) => {
          this.form = response.data.books[0];
          this.form.user_name = this.$store.state.current_user;
          this.recommend = this.form.recommend == "推荐";
        });
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page +
        "/" +
        this.$route.query.filmID;
      this.title = "修改影片信息";
      this.add_update_type = 1;
      this.submit = "修改";
    } else {
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page;
      this.form.user_name = this.$store.state.current_user;
      this.title = "新增影片信息";
      this.add_update_type = 0;
      this.submit = "新增";
    }
  },
};
</script>
<style>
.update_image:hover {
  cursor: pointer;
  transform: scale(1.2, 1.2);
  transition: 0.5s transform;
}
.el-form-item__label {
  font-size: 14px !important;
}

.el-button--primary {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner,
.el-radio__input.is-checked .el-radio__inner {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-radio__input.is-checked + .el-radio__label {
  color: #17a2b8 !important;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #17a2b8 !important;
}

.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #17a2b8 !important;
}

.el-month-table,
.el-year-table {
  font-size: 1vw;
}

.el-radio-button__inner,
.el-radio-group {
  vertical-align: baseline;
}
</style>
